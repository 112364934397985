<template>
  <div class="pagesxpurshasetable" style="width: 100%;height: 100%;">
    <el-drawer size="335px" :modal="false" :append-to-body="false" :modal-append-to-body="false" :visible.sync="drawer"
      :with-header="false">
      <div class="drawer_box">
        <el-row style="margin-bottom: 10px;" class="flexEnd flex_1 ">
          <el-button size="small" type="primary" icon="el-icon-search" @click="onSearch">查询
          </el-button>
          <el-button size="small" type="primary" plain @click="onReset">重置
          </el-button>
        </el-row>
        <el-divider style="width: 100%"></el-divider>
        <el-form :model="searchForm" ref="queryForm" label-width="10px">
          <el-row>筛选</el-row>
          <el-row>
            <el-col :span="24" class="labelCss">
              <el-form-item label="耗材名称" label-width="80px">
                <el-input v-model="searchForm.hc_title" size="mini" placeholder="耗材名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="labelCss">
              <el-form-item label="预估总价:" label-width="80px">
                <div class="flexStart">
                  <el-input-number v-model="searchForm.min_howmuch" size="mini" placeholder="最小值"></el-input-number>
                  <span style="margin: 0 10px;">-</span>
                  <el-input-number v-model="searchForm.max_howmuch" size="mini" placeholder="最大值"></el-input-number>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col v-if="false" :span="24" class="labelCss">
              <el-form-item label="耗材分类" label-width="80px">
                <el-cascader ref="myCascader1" v-model="searchForm.zc_typeid" :options="typeList" size="small"
                  :props="optionProps1" :show-all-levels="true" filterable placeholder="耗材分类"
                  @change="handleCascaderChange1" style="width: 100%;"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="labelCss">
              <el-form-item label="状态" label-width="80px">
                <el-select v-model="searchForm.sp_status" size="small" placeholder="状态" style="width: 100%;">
                  <el-option label="待审核" value="0"></el-option>
                  <el-option label="通过" value="1"></el-option>
                  <el-option label="驳回" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" class="labelCss">
              <el-form-item label="申购日期" label-width="80px">
                <el-date-picker size="small" style="width: 100%;" v-model="searchForm.dateRange" type="daterange"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>


          </el-row>
        </el-form>
      </div>
    </el-drawer>

    <el-row style="margin-bottom: 10px;" class="flexBetween flex_1 ">
      <div class="flex_1 flexStart">
        <el-input size="small" v-model="searchForm.title" clearable style="width: 300px!important;" placeholder="模糊搜索">
          <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
        </el-input>
        <el-cascader ref="treeCascader" size="small" :options="deptTree" :props="deptCascaderProps"
          v-model="searchForm.dept_id" :show-all-levels="false" @change="changeDept" placeholder="请选择所属部门" clearable
          style="margin-left: 10px;">
        </el-cascader>
        <el-date-picker size="small" style="width: 300px;margin-left: 10px;" v-model="searchForm.dateRange"
          value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>

        <el-select size="small" v-model="searchForm.sp_status" placeholder="状态" style="width: 150px;margin-left: 10px;">
          <el-option label="待审核" value="0" style="color: #1289ff;"></el-option>
          <el-option label="审核通过" value="1" style="color: #67c23a;"></el-option>
          <el-option label="审核驳回" value="2" style="color: #f56c6c;"></el-option>
        </el-select>
        <el-cascader v-if="false" ref="myCascader1" v-model="searchForm.zc_typeid" :options="typeList" clearable
          size="small" :props="optionProps1" :show-all-levels="true" filterable placeholder="耗材分类"
          @change="handleCascaderChange1" style="margin-left: 10px;"></el-cascader>
        <el-button size="small" icon="el-icon-search" @click="onSearch" style="margin-left: 10px;" type="primary"
          plain>查询
        </el-button>
        <el-button size="small" icon="el-icon-search" @click="onReset" style="margin-left: 10px;" type="warning"
          plain>重置</el-button>

      </div>

    </el-row>

    <el-row style="margin-bottom: 10px;" class="flexBetween flex_1 ">
      <div class="flex_1 flexStart">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
        <el-button icon="el-icon-upload" size="small" type="primary" plain style="margin-left: 10px;"
          @click="showImportDialog = true">导入</el-button>
        <el-button icon="el-icon-download" size="small" type="primary" plain style="margin-left: 10px;"
          @click="exportExcel">导出
        </el-button>
        <el-popover ref="mypopover" placement="right-start" width="240" trigger="click" title="导出xlsx表格列配置">
          <div class="" style="width:100%;padding: 10px 0;border-top: 1px solid #efefef;">
            <div class="flexBetween" v-for="(item, index) in exportFields" style="margin-top: 10px;">
              <span>{{ item.name }}</span>
              <el-switch :disabled="item.disabled" v-model="item.value" active-color="#2469f3">
              </el-switch>
            </div>
          </div>

        </el-popover>

        <el-button v-popover:mypopover type="primary" size="small" plain icon="el-icon-setting"
          style="margin: 0;"></el-button>

        <el-button icon="el-icon-printer" size="small" type="primary" plain style="margin-left: 10px;"
          @click="batchToPrint">批量打印</el-button>

        <!-- <el-select class="ml10" v-model="searchForm.course_id" filterable size="small" placeholder="请先选择课程再导出采购清单"
          style="width: 235px;">
          <el-option :label="item.course_name" :value="item.course_id" v-for="(item, i) in courseList"
            :key="i"></el-option>
        </el-select> -->
        <!-- <el-button icon="el-icon-download" size="small" type="primary" plain style=""
          @click="exportExcel_cgList()">导出耗材采购清单
        </el-button> -->
      </div>

      <div>
        <el-button type="text" @click="drawer = true" style="color: #999999;margin-left: 20px;">
          <i class="el-icon-s-operation"></i>&nbsp;高级搜索
        </el-button>
      </div>
    </el-row>

    <el-table :data="DataList" height="calc( 100% - 100px)" @selection-change="handleSelectionChange"
      style="width: 100%;" stripe border>
      <el-table-column type="selection" width="55" fixed="left" align="center" />
      <el-table-column type="index" label="序号" width="50" fixed="left" align="center" />
      <el-table-column prop="sg_num" label="申购单号" width="150" :show-overflow-tooltip="true" fixed="left"
        align="center" />
      <el-table-column prop="sp_status" label="状态" width="150" :show-overflow-tooltip="true" fixed="left">
        <template slot-scope="scope">
          <el-tag size="mini" type="primary"
                  v-if="scope.row.sp_status == 0 && (!scope.row.sp_times || scope.row.icansp || userinfo.id == scope.row.create_uid)">待审核</el-tag>
          <el-tag size="mini" type="primary"
                  v-if="scope.row.sp_status == 0 && scope.row.sp_times && !scope.row.icansp && userinfo.id != scope.row.create_uid">待他人审核</el-tag>
          <el-tag size="mini" type="success" v-if="scope.row.sp_status == 1">审核通过</el-tag>
          <el-tag size="mini" type="danger" v-if="scope.row.sp_status == 2">审核驳回</el-tag>
          <el-tag size="mini" type="primary" v-if="scope.row.sp_status == 1 && scope.row.sfrk == 0"
            style="margin-left: 10px;">待入库
          </el-tag>
          <el-tag size="mini" type="success" v-if="scope.row.sp_status == 1 && scope.row.sfrk == 1"
            style="margin-left: 10px;">已入库
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="申请人" width="120" align="center" />
      <el-table-column prop="dept_name" label="所在部门" width="120" align="center" show-overflow-tooltip />
      <el-table-column prop="title" label="申购物品" width="200" :show-overflow-tooltip="true" align="center">
        <template slot-scope="scope" style="width: calc( 100% - 20px );">
          <div @click="view(scope.row)" style="color: #409EFF;cursor: pointer;width: 100%;display: block;"
            class="overOneLine">
            {{ scope.row.desc }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="cdate" label="申购时间" width="180" align="center">
        <template slot-scope="scope">
          <span style="font-size: 14px;">{{ scope.row.cdate }}</span>
        </template>
      </el-table-column>


      <el-table-column prop="cdate" label="审核时间" width="180" align="center">
        <template slot-scope="scope">
          <span style="font-size: 14px;" v-if="scope.row.sp_status != 0">{{ scope.row.udate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="申购原因" width="200" align="center">
        <template slot-scope="scope" style="width: calc( 100% - 20px );">
          <el-tooltip :enterable="false"
            :disabled="!scope.row.remark || (scope.row.remark && scope.row.remark.length < 28)" placement="top-start">
            <div slot="content" style="width: 400px;">{{ scope.row.remark }}</div>
            <div style="width: 100%;" class="overMoreLine">
              {{ scope.row.remark }}
            </div>
          </el-tooltip>

        </template>
      </el-table-column>
      <el-table-column prop="howmuch" label="预估总价(元)" width="140" align="center" />

      <!--      <el-table-column prop="pinpai" label="品牌" />-->
      <!--      <el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true" />-->

      <el-table-column label="操作" min-width="150" fixed="right">
        <template slot-scope="scope">
          <div class="flexStart">
            <el-tag size="mini" style="cursor: pointer;font-size: 14px;" @click="view(scope.row)">查看</el-tag>
            <el-tag v-if="scope.row.sp_status == 0 && userinfo.id == scope.row.create_uid" size="mini"
              style="margin-left: 10px;cursor: pointer;color: #409eff;font-size: 14px;" @click="edit(scope.row)"><i
                class="el-icon-edit"></i></el-tag>
            <el-tag v-if="scope.row.sp_status == 0 && userinfo.id == scope.row.create_uid" size="mini" type="danger"
              style="cursor: pointer;margin-left: 10px;font-size: 14px;" @click="removeItem(scope.row)">
              <i class="el-icon-delete"></i>
            </el-tag>

            <!--          <el-tag v-if="scope.row.sp_status != 1" size="mini" style="cursor: default;margin-left: 10px;color: transparent;background-color:transparent;border: 0;">申购单</el-tag>-->
            <el-tag v-if="scope.row.icansp" type="warning" size="mini"
              style="margin-left: 10px;cursor: pointer;font-size: 14px;" @click="view(scope.row, 'verify')">审核
            </el-tag>
            <el-tag size="mini" type="success" style="cursor: pointer;margin-left: 10px;font-size: 14px;"
              @click="print(scope.row)">打印
            </el-tag>
          </div>
          <div> <el-tag size="mini" type="success" style="cursor: pointer;font-size: 14px;"
              @click="exportExcel_cgList(scope.row)">导出耗材采购清单
            </el-tag></div>
          <div> <el-tag size="mini" type="success" style="cursor: pointer;font-size: 14px;"
              @click="exportWord_ysList(scope.row)">导出耗材验收单
            </el-tag></div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
      :current-page.sync="page.current_page" :page-sizes="[10, 50, 100]" :page-size="page.per_page"
      layout="total,sizes, prev, pager, next" :total="page.count">
    </el-pagination>


    <!--导出-->
    <ExportTable :exportList="exportList" :exportColumns="exportColumns" type="hc"></ExportTable>

    <!--    打印耗材清单-->
    <PrintHC v-if="dialogVisible_printHC" :searchForm="searchForm" :dialogTitle="dialogTitle"
      :dialogVisible_printHC="dialogVisible_printHC" type="hc"></PrintHC>


    <!--导入dialog-->
    <el-dialog title="批量导入" :visible="showImportDialog" :close-on-click-modal="false" width="900px"
      custom-class="cus_dialog cus_dialog_print" @close="showImportDialog = false">
      <el-form class="" :model="importParams" ref="addzc" label-width="120px" style="width:calc( 100% - 10px );">
        <el-form-item>
          <el-button icon="el-icon-download" type="text">
            <a href="./downloadFiles/耗材申购导入模板.xls" target="_blank"
              style="text-decoration: none; color: inherit;font-size: 14px;">下载模板</a>
          </el-button>
        </el-form-item>
        <el-form-item label="购买原因:" prop="remark">
          <el-input type="textarea" :autosize="{ minRows: 3 }" v-model="importParams.remark" size="mini"
            :disabled="disabled" placeholder="请输入申购理由,用途等"></el-input>
        </el-form-item>
        <el-form-item label="所属部门">
          <el-cascader ref="treeDept" :options="deptTree" :props="deptCascaderProps" v-model="importParams.dept_id"
            :show-all-levels="false" @change="getDeptName" placeholder="请选择" style="width: 300px;" clearable>
          </el-cascader>
        </el-form-item>
        <!--        <el-form-item label="图片:">
          <div class="flexStart">
            <div class="updimage" v-for="(f, idx) in files"
                 :style="{ backgroundImage: 'url(' + f.src + ')' }"
                 style="position: relative">
              <img :src="f.src" @click="handlePictureCardPreview(f.src)"
                   style="opacity: 0;width: 100%;height: 100%;"/>
              <div class="flexCenter" @click="delImage(idx)"
                   style="position: absolute;top: -8px;right: -8px;width:20px;height:20px;
                          color: #fff;background-color:#409eff;border-radius: 50%;border: 1px solid #fff;cursor: pointer;">
                <i class="el-icon-close"></i>
              </div>
            </div>
            <el-upload v-if="files.length < 3" action="" :http-request="uploadOss" :on-success="uploadCover"
                       :on-remove="handleRemove" :show-file-list="false" name="image">
              <div class="flexCenter uploadBox"
                   style="width: 100px;height: 100px;border:1px dashed #909399;">
                <i class="el-icon-plus" style="font-size: 26px;"></i>
              </div>
            </el-upload>
          </div>
        </el-form-item>-->
        <el-form-item>
          <el-upload style="display: inline-block; margin-right: 20px" action="" :http-request="uploadTemp"
            :on-success="uploadFiles" :show-file-list="false">
            <el-button size="small" icon="el-icon-upload2" type="primary">选择文件
            </el-button>
          </el-upload>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--打印单-->
    <el-dialog title="打印预览" :visible="dialogVisible_print" :close-on-click-modal="false" width="900px"
      custom-class="cus_dialog cus_dialog_print" @close="dialogVisible_print = false">
      <div style="text-align: right; position: absolute; top:2px; right: 50px;">
        <el-button v-print="'#printMe'" icon="el-icon-printer" type="warning">打印</el-button>
      </div>
      <MergeSg v-if="dialogVisible_print" :sgInfo="sgInfo"></MergeSg>
    </el-dialog>
  </div>
</template>

<script>
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from "moment";
import MergeSg from "@/h5/print/mergeSg.vue";
import { ExportBriefDataDocx, getBase64Sync } from "@/utils/exportBriefDataDocx";

export default {
  components: {
    ExportTable: () => {
      return import('../../components/ExportTable.vue')
    },
    PrintHC: () => {
      return import('./PrintHCList.vue')
    },
    MergeSg
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value && value != 0) {
        return callback(new Error('请填写入库数量!'));
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'));
      } else {
        if (value <= 0) {
          callback(new Error('不能小于0'));
        } else {
          callback();
        }
      }
    };

    return {
      dialogVisible_printHC: false,
      exportFields: [
        { name: '申购单号', field: 'sg_num', value: true, disabled: true, },
        { name: '状态', field: 'sp_status_Name', value: true, disabled: false, },
        { name: '申购人', field: 'username', value: true, disabled: true, },
        { name: '所在部门', field: 'dept_name', value: true, disabled: false, },
        { name: '申购项目描述', field: 'desc', value: true, disabled: false, },
        { name: '申购时间', field: 'cdate', value: true, disabled: false, },
        { name: '申购原因', field: 'remark', value: true, disabled: false, },
        { name: '预估总价(元)', field: 'howmuch', value: true, disabled: false, },
      ],
      exportColumns: [],
      exportList: [],
      searchForm: {},
      DataList: [],
      multipleSelection: [],
      queryForm: {},
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      dialogTitle: '新增耗材',
      dialogVisible: false,
      EditItem: {},
      disabled: false,
      typeList: [],
      areaList: [],
      optionProps: { value: 'id', label: 'storename', emitPath: false, checkStrictly: true },
      optionProps1: { value: 'id', label: 'cname', emitPath: false },
      files: [],
      logList: [],
      dialogVisible_borrow: false,
      userinfo: {},
      temp_list: [],//todo 未用备用
      showUserDialog: false,
      userDialogType: null,
      dialogVisible_return: false,
      dialogVisible_distribute: false,
      dialogVisible_shenling: false,
      dialogVisible_withdraw: false,
      drawer: false,//高级搜索面板
      FieldItem: {},
      showCusField: false,
      curDate: '',
      printObj: {
        id: "#printDiv",
        popTitle: "",
        extraCss: "",
        extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
      },
      showPrinter: false,
      showBiaoqian: false,
      bqZcList: [],
      dialogErrorVisible: false,
      ErrLog: [],
      page_hc: {
        current_page: 1,
        pre_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      storeNum: null,
      rules_store: {
        num: [
          { validator: checkNum, trigger: 'blur' }
        ],
      },
      purchaseType: [],
      pinpaiList: [],
      xinghaoList: [],
      sxDataList: [],

      deptTree: [],
      deptCascaderProps: {
        label: "dept_name",
        value: "id",
        children: "children",
        emitPath: false,
        checkStrictly: true,
      },
      showImportDialog: false,
      importParams: {},
      process_code: "hcsg",
      process_id: 0,
      dialogVisible_print: false,
      sgInfo: {},
      courseList: [],

    }
  },
  mounted() {
    this.getCreateUser()

    this.getList();
    this.getOrgList()

    this.getSXList();
    this.getType();
    this.getArea();
    this.getCurDate();

    this.getPurchaseType()
    this.getPinPaiList()
    this.getXinghaoList()
    this.getProcessID()

    // 为导出采购单加的课程选择
    // this.getCourse()
  },
  methods: {
    getCourse() {
      let termIdInit = ''
      this.$http.post(`/api/t_sch_terms_list`).then((res) => {
        if (res && res.data.data && res.data.data.length > 0) {
          this.termList = res.data.data;
          res.data.data &&
            res.data.data.map((a) => {
              if (this.globalCurrentDate > a.term_start && this.globalCurrentDate < a.term_end) {
                this.termIdInit = a.id;
                this.queryForm.term_id = this.termIdInit;
              }
            });
          if (!this.termIdInit) {
            this.termIdInit = res.data.data[0].id;
            this.queryForm.term_id = this.termIdInit;
          }
        }

        this.$http
          .post("/api/t_sch_syskc_list", {
            pagesize: 200,
            term_id: 41
          })
          .then((res) => {
            let courseList = res.data;

            this.courseList = this._lo.uniqBy(courseList, (item) => item.course_id);
            // this.getTeacherClass()
          });
      });

    },
    getOrgList() {
      let _this = this;
      return new Promise((resolve) => {
        this.$http.post("/api/teacher_department_tree").then((res) => {
          if (res.data && res.data.length > 0) {
            _this.deleteEmptyGroup(res.data).then((result) => {
              _this.deptTree = result;
            });
          }
        });
      });
    },
    changeDept(e) {
      let labels = this.$refs.treeCascader.getCheckedNodes()[0].pathLabels
      if (labels && labels.length > 0) {
        this.searchForm.dept_name = labels[labels.length - 1]
        console.log(labels[labels.length - 1])
      }
    },
    exportExcel(type) {
      let _this = this
      if (type == 'hc') {
        // this.dialogTitle = `${_this.searchForm.dateRange ? _this.searchForm.dateRange[0] + '-' : ''}${_this.searchForm.dateRange ? _this.searchForm.dateRange[1] : ''} ${_this.searchForm.dept_name ? _this.searchForm.dept_name : ''} 实训室耗材采购清单`
        // this.dialogVisible_printHC = true

        let _this = this
        let params = JSON.parse(JSON.stringify(this.searchForm)), arr = []
        params.title = _this.searchForm.title
        params.page = _this.page.current_page ? _this.page.current_page : 1
        params.pagesize = _this.page.per_page ? _this.page.per_page : 50
        params.type = 'sx'
        params.sDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[0]} 00:00:00` : ''
        params.eDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[1]} 23:59:59` : ''
        delete params.dept_name
        this.$http.post("/api/export_tch_shengou", params).then(res => {
          window.open(res.data.url, "_blank")
        })


      } else {
        console.log(this.exportFields)
        this.exportList = []
        const loading = this.$loading({
          lock: true,
          text: '数据准备中，请稍等...',
          spinner: 'el-icon-loading',
        });
        this.exportFields = [
          { name: '申购单号', field: 'sg_num', value: true, disabled: true, },
          { name: '状态', field: 'sp_status_Name', value: true, disabled: false, },
          { name: '申购人', field: 'username', value: true, disabled: true, },
          { name: '所在部门', field: 'dept_name', value: true, disabled: false, },
          { name: '申购项目描述', field: 'desc', value: true, disabled: false, },
          { name: '申购时间', field: 'cdate', value: true, disabled: false, },
          { name: '申购原因', field: 'remark', value: true, disabled: false, },
          { name: '预估总价(元)', field: 'howmuch', value: true, disabled: false, },
        ]
        if (this.multipleSelection && this.multipleSelection.length > 0) {
          loading.close();
          this.exportList = this.multipleSelection
          _this.preSaveExcel()
        } else {
          this.getExportList().then(r => {
            loading.close();
            _this.preSaveExcel()
          })
        }
        setTimeout(() => {
          loading.close();
        }, 2000);
      }

    },
    exportExcel_cgList(item) {
      // this.dialogTitle = `${_this.searchForm.dateRange ? _this.searchForm.dateRange[0] + '-' : ''}${_this.searchForm.dateRange ? _this.searchForm.dateRange[1] : ''} ${_this.searchForm.dept_name ? _this.searchForm.dept_name : ''} 实训室耗材采购清单`
      // this.dialogVisible_printHC = true
      console.log(item)
      let _this = this
      let params = JSON.parse(JSON.stringify(this.searchForm)), arr = []
      params.title = _this.searchForm.title
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      params.type = 'sx'
      params.sDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[0]} 00:00:00` : ''
      params.eDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[1]} 23:59:59` : ''
      params.eDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[1]} 23:59:59` : ''
      params.sg_id = item.id
      // console.log(params, item)
      delete params.dept_name
      this.$http.post("/api/export_tch_shengou", params).then(res => {
        window.open(res.data.url, "_blank")
      })
    },
    getExportList(type) {

      if (type == 'hc') {
        let _this = this
        let params = JSON.parse(JSON.stringify(this.searchForm)), arr = []
        params.title = _this.searchForm.title
        params.page = _this.page.current_page ? _this.page.current_page : 1
        params.pagesize = _this.page.per_page ? _this.page.per_page : 50
        params.type = 'sx'
        params.sDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[0]} 00:00:00` : ''
        params.eDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[1]} 23:59:59` : ''
        delete params.dept_name
        this.$http.post("/api/export_tch_shengou", params).then(res => {
          window.open(res.data.url, "_blank")
        })
      } else {
        return new Promise(resolve => {
          let _this = this
          let params = {}, arr = []
          params.title = _this.searchForm.title
          params.page = 1
          params.pagesize = 10000000000
          params.type = 'sx'
          params.sDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[0]} 00:00:00` : ''
          params.eDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[1]} 23:59:59` : ''
          let url = "/api/sx_sg_list"
          if (type == 'hc') {
            url = '/api/sx_sg_hc_list'
            params.title = ''
            params.dept_id = _this.searchForm.dept_id
          }
          this.$http.post(url, params).then(res => {
            if (res.data.data && res.data.data.length > 0) {
              res.data.data.map(a => {
                if (a.sp_status == 0) {
                  a.sp_status_Name = '待审核'
                }
                if (a.sp_status == 1) {
                  a.sp_status_Name = '审核通过'
                }
                if (a.sp_status == 2) {
                  a.sp_status_Name = '审核驳回'
                }
                a.match = 1
                a.listLen = 0
                if (a.hc_list) {
                  a.list = JSON.parse(a.hc_list)
                  a.desc = ``
                  a.list && a.list.map(h => {
                    a.desc = `${a.desc}${h.title || '-'}${h.cgNum || '-'}${h.jldw};`
                    if (h.cgNum != h.rkNum) {
                      a.match = 0
                    }
                  })
                  a.listLen = a.list.length
                }
              })
              this.exportList = res.data.data
            }
            resolve()
          })

        })
      }
    },
    preSaveExcel() {
      let _this = this
      let arr = []
      this.exportFields.map(a => {
        if (a.value) {
          arr.push(a)
        }
      })
      this.exportColumns = arr
      if (!this.exportList || this.exportList.length == 0) {
        this.$message.warning('暂无数据可导出~')
      }
      setTimeout(function () {
        let name = '申购列表导出'
        _this.saveExcel(name)
      }, 500)
    },
    saveExcel(saveTitle) {
      let _this = this
      var xlsxParam = { raw: true }
      let wb = XLSX.utils.table_to_book(document.querySelector('#exportTable'), xlsxParam);
      let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${saveTitle ? saveTitle : "导出"}${_this.curDate}.xlsx`);
      } catch (e) {
        if (typeof console !== 'undefined')
          console.log(e, wbout)
      }
      return wbout
    },

    //导出耗材验收单
    exportWord_ysList(item) {
      this.$http.post('/api/t_sch_terms_cur', { cdate: item.cdate }).then((res) => {
        const data = { term_name: "", course_name: '', list: [] };
        if (res && res.data) {
          data.term_name = res.data.title;
        }
        data.course_name = item.list[0].course;
        item.list.forEach((hc, index) => {
          hc.index = index + 1;
          hc.checked = "合格 ☑  不合格 □"
          data.list.push(hc);
        })

        ExportBriefDataDocx("/static/ImportTemps/耗材导出验收单_new.docx", data, `${item.dept_name}${res.data.term_year}年度${data.course_name}课程耗材验收单_${parseInt(Math.random() * 1000000)}.docx`)
      })

    },

    getCreateUser() {

      this.$http.post("/api/get_teacher_info").then(res => {
        this.userinfo = res.data
        this.importParams.dept_id = this.userinfo.dept.length ? this.userinfo.dept[0].id : null;
        this.importParams.dept_name = this.userinfo.dept.length ? this.userinfo.dept[0].dept_name : null;
      })
    },
    getList() {
      let _this = this
      _this.DataList = [];
      let params = JSON.parse(JSON.stringify(_this.searchForm)), arr = []
      params.title = _this.searchForm.title
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      params.type = 'sx'
      params.sDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[0]} 00:00:00` : ''
      params.eDate = _this.searchForm.dateRange ? `${_this.searchForm.dateRange[1]} 23:59:59` : ''
      delete params.dept_name
      this.$http.post("/api/sx_sg_list", params).then(res => {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.map(a => {
            if (a.sp_status == 0) {
              let process = a.process_json ? JSON.parse(a.process_json) : []
              a.sp_times = process.filter(p => {
                return p.sp_users.filter(s => s.spover).length
              }).length

              a.sp_status_Name = '待审核'
            }
            if (a.sp_status == 1) {
              a.sp_status_Name = '审核通过'
            }
            if (a.sp_status == 2) {
              a.sp_status_Name = '审核驳回'
            }
            a.match = 1
            a.listLen = 0
            if (a.hc_list) {
              a.list = JSON.parse(a.hc_list)
              a.desc = ``
              a.list && a.list.map(h => {
                a.desc = `${a.desc}${h.title || ''}${h.cgNum || '0'}${h.jldw || ''};`
                if (h.cgNum != h.rkNum) {
                  a.match = 0
                }

              })
              a.listLen = a.list.length
            }
          })
          setTimeout(function () {
            _this.DataList = res.data.data;
          }, 50)
        }
        if (res.data && res.data.page) {
          this.page = res.data.page
          this.page_hc = res.data.page
        }

      })
    },
    getSXList() {
      let _this = this
      let params = {}, arr = []
      params.title = _this.searchForm.title
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      this.$http.post("/api/sx_hc_list", params).then(res => {
        this.sxDataList = res.data.data;
        this.page1 = res.data.page
        this.page_hc1 = res.data.page
      })
    },
    getPurchaseType() {
      this.$http.post("/api/sys_dictionary_list", { key_type: "purchaseType" }).then(res => {
        if (res.data && res.data.length > 0) {
          this.purchaseType = JSON.parse(res.data[0].key_values)
        }
      })
    },
    getPinPaiList() {
      this.$http.post("/api/sx_hc_pinpai", { zc_typeid: this.searchForm.zc_typeid }).then(res => {
        if (res.data && res.data.length > 0) {
          this.pinpaiList = res.data
        }
      })
    },
    getXinghaoList() {
      this.$http.post("/api/sx_hc_xinghao", { zc_typeid: this.EditItem.zc_typeid }).then(res => {
        if (res.data && res.data.length > 0) {
          this.xinghaoList = res.data
        }
      })
    },

    // 新增
    add() {
      this.$$parent(this, 'add').then(r => {
      })
    },
    editItem(row, flag) {
      this.$$parent(this, 'editItem', row, flag).then(r => {
      })

    },
    removeItem(item) {
      this.$$parent(this, 'removeItem', item).then(r => {
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },
    delSlct() {
      this.$$parent(this, 'delSlct', this.multipleSelection).then(r => {
      })
    },
    edit(row, type) {
      this.$$parent(this, 'editItem', row).then(r => {
      })
    },
    view(row, type) {
      this.$$parent(this, 'view', row, type).then(r => {
      })
    },
    print(row) {
      this.$$parent(this, 'print', row).then(r => { })
    },
    //导入
    uploadXls() {
      document.getElementById('addXlsbtn').click()
    },
    uploadAttch(e) {
      this.$$parent(this, 'uploadAttch', e).then(r => {
      })
    },

    //增加库存
    showStore(row) {
      this.$$parent(this, 'showStore', row).then(r => {
      })
    },


    onSearch() {
      this.page.current_page = 1
      this.getList()
    },
    onReset() {
      this.searchForm = {}
      this.page.current_page = 1
      this.getList()
    },

    dep_Arr(arr) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.store_num <= 0) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },


    getType() {
      let _this = this
      this.$http.post("/api/zc_type", { type: 'sx' }).then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.typeList = result
        })
      })
    },
    getArea() {
      let _this = this
      this.$http.post("/api/zc_store").then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.areaList = result
        })
      })
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },

    showDetails(row) {
      let _this = this
      this.editItem(row, true)
      this.dialogTitle = '耗材详情'
      this.dialogVisible = true
      this.disabled = true
    },

    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.per_page = val
      this.getList();
    },
    paginate(val) {
      this.page.current_page = val
      this.getList();
    },

    handleCascaderChange(e) {
      console.log(e)
    },
    handleCascaderChange1(e) {
      if (e && this.searchForm.zc_typeid) {
        this.getPinPaiList()
        this.getXinghaoList()
      }
    },

    showUser(type) {
      if (type) {
        this.userDialogType = type
      }
      this.showUserDialog = true
    },

    getUser(e) {
      console.log(e)
      let _this = this
      let arr = []
      if (e && e.length > 0) {
        if (this.userDialogType == 1) {
          e.map((item, idx) => {
            arr.push({
              union_id: item.union_id,
              name: item.name,
            })
            if (_this.EditItem.target_users && _this.EditItem.target_users.length > 0) {
              _this.EditItem.target_users.map(orgnl => {
                if (orgnl.union_id == item.union_id) {
                  arr.splice(idx, 1)
                }
              })
            }
          })
          _this.EditItem.target_users = _this.EditItem.target_users.concat(arr)
          _this.EditItem = { ..._this.EditItem }
        } else if (this.userDialogType == 2) {
          _this.EditItem.target_uid = e[0].union_id
          _this.EditItem.name = e[0].name
          _this.EditItem = { ..._this.EditItem }
        }

      }
      _this.showUserDialog = false
    },

    getCurDate() {
      let d = new Date();
      let year = d.getFullYear(); //获取完整的年份(4位)
      let month = d.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let date = d.getDate(); //获取当前日(1-31)
      d.getDay(); //获取当前星期X(0-6,0代表星期天)
      d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
      d.getHours(); //获取当前小时数(0-23)
      d.getMinutes(); //获取当前分钟数(0-59)
      d.getSeconds(); //获取当前秒数(0-59)
      d.getMilliseconds(); //获取当前毫秒数(0-999)
      d.toLocaleDateString(); //获取当前日期
      let mytime = d.toLocaleTimeString(); //获取当前时间
      d.toLocaleString(); //获取日期与时间
      this.curDate = `${year}-${month}-${date}`
    },
    uploadFiles(e) {
      this.$http
        .post('/api/sx_sg_import', {
          url: e.src,
          zc_type: 'sx',
          process_id: this.process_id,
          ...this.importParams
        })
        .then((res) => {
          if (res.data.errors && res.data.errors.length > 0) {
            let html = "";
            for (let msg of res.data.errors) {
              html += `<div style='font-size:12px'>${msg}</div>`;
            }
            this.$alert(html, "未导入数据", {
              dangerouslyUseHTMLString: true,
            });
          } else {
            this.$message.success("导入成功");
          }
          this.getList();
          this.showImportDialog = false;
        });
    },
    getDeptName(e) {
      let node = this.$refs.treeDept.getCheckedNodes()
      this.importParams.dept_name = node[0] ? node[0].label : null
    },
    getProcessID() {
      this.$http.post("/api/form_process", {
        process_code: this.process_code
      }).then(res => {
        this.process_id = res.data.id

      })
    },
    batchToPrint() {
      if (!this.multipleSelection.length) {
        this.$message.error('请勾选需要打印的行')
        return
      }
      let hcList = []
      let processes = []
      let data = this.multipleSelection.concat()
      let sg = { ...data[0] }
      data = data.filter(d => d.create_uid === sg.create_uid)
      sg.total_price = data.reduce((p, item) => {
        return p + item.howmuch
      }, 0)
      sg.print_time = moment().format("YYYY-MM-DD HH:mm")
      data.forEach(item => {
        hcList = hcList.concat(JSON.parse(item.hc_list))
        processes.push(JSON.parse(item.process_json))
      })
      sg.hc_list = hcList
      sg.processes = processes
      this.dialogVisible_print = true
      this.sgInfo = sg
    },
  }
}
</script>

<style lang="less" type="text/scss">
.pagesxpurshasetable {
  box-sizing: border-box;

  div {
    box-sizing: border-box;
  }

  .el-button-group_cus {
    .el-button--primary:first-child {
      border-right-color: #2469f3;
    }
  }


}



.pagezclist {
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .labelCss {
    .el-form-item__label {
      color: #909399 !important;
      font-size: 12px;
    }
  }

  .el-input,
  .el-select,
  .el-cascader {
    width: 100% !important;
  }

  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown+.el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF !important;
      background-color: #Ecf5ff !important;
      border-color: #b3d8ff !important;
    }
  }

  .el-divider {
    margin-top: 0;
  }

  .el-drawer__wrapper {
    top: 70px;
  }

  .drawer_box,
  .targetUsers {
    padding: 10px;

    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .updimage {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
    border: 1px dotted #ccc;
    background-size: 100% auto;
    background-position: center;
    overflow: hidden;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .cus_page {
    margin-top: 10px;

    .el-pagination__sizes {
      width: 130px;
    }
  }
}
</style>
