<template>
  <div>
    <div class="pdfok" id="pdfok" v-if="pdfok">
    <div id="printMe" style=" width: 740px; margin: 0 auto;">
      <div class=" A4Container">
        <div class="" style="padding: 0!important;">
          <div class="table" style="">
            <el-row class="flexStretch borderR cell flexCenter"
                    style="height: 50px;font-weight: 700">{{config.schoolname}}耗材申购单
            </el-row>
            <el-row class="flexStretch borderR ">
              <el-col :span="4" class="cell flexCenter" style="">申请人</el-col>
              <el-col :span="4" class="cell flexCenter">{{sgInfo.username||'-'}}</el-col>
              <el-col :span="3" class="cell flexCenter" style="">所在部门</el-col>
              <el-col :span="4" class="cell flexCenter" style="padding: 0 3px;">{{sgInfo.dept_name||'-'}}</el-col>
              <el-col :span="3" class="cell flexCenter" style="">打印时间</el-col>
              <el-col :span="6" class="cell flexCenter">{{sgInfo.print_time||'-'}}</el-col>
            </el-row>
            <el-row class="flexStretch borderR">
              <el-col :span="4" class="cell flexCenter">申购单号</el-col>
              <el-col :span="20" class="cell flexCenter" style="padding: 0 10px;font-weight: 700;">{{sgInfo.sg_num}}</el-col>
            </el-row>
            <el-row class="flexStretch borderR ">
              <el-col :span="4" class="cell flexCenter" style="">预估总价(元)</el-col>
              <el-col :span="20" class="cell flexCenter">
                <span>￥{{sgInfo.total_price||'0'}}</span>&nbsp;|&nbsp;<span>{{sgInfo.total_price?upperCaseMoney(sgInfo.total_price):upperCaseMoney(0)}}</span>
              </el-col>
            </el-row>
            <el-row class="flexStretch borderR borderB">
              <el-col :span="4" class="cell flexCenter" style="">采购原因</el-col>
              <el-col :span="20" class="cell flexCenter breakWord" style="padding: 0 3px;">{{sgInfo.remark||'-'}}</el-col>
            </el-row>
          </div>
        </div>



        <div class="" style="padding: 0!important;margin-top: 20px;">
          <div class="table" style="">
            <el-row class="flexStretch borderR borderB" style="">
              <el-col :span="4" class="cell flexCenter" style="font-weight: 700">
                采购清单
              </el-col>
              <el-col :span="20" class="" style="">
                <el-row>
                  <el-col :span="8" class="cell flexCenter" style="">耗材名称</el-col>
                  <el-col :span="4" class="cell flexCenter" style="">品牌</el-col>
                  <el-col :span="4" class="cell flexCenter" style="">型号</el-col>
                  <el-col :span="4" class="cell flexCenter">数量</el-col>
                  <el-col :span="4" class="cell flexCenter">金额(元)</el-col>
                </el-row>

                <el-row v-for="(item, index) in sgInfo.hc_list" class="flexStretch">
                  <el-col :span="8" class="cell flexStart" style="padding: 0 3px;">{{ item.title }}</el-col>
                  <el-col :span="4" class="cell flexCenter" style="padding: 0 3px;">{{ item.pinpai || '-' }}</el-col>
                  <el-col :span="4" class="cell flexCenter" style="padding: 0 3px;">{{ item.xinghao || '-' }}</el-col>
                  <el-col :span="4" class="cell flexCenter">{{ item.cgNum }}{{ item.jldw || '' }}</el-col>
                  <el-col :span="4" class="cell flexCenter">{{ item.cgJine }}</el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>

        <div v-for="(processes,index) in sgInfo.processes" class="" style="padding: 0!important;margin-top: 20px;" :key="index + 'sglist' ">
          <div class="table" style="">
            <el-row class="flexStretch borderR borderB" style="">
              <el-col :span="4" class="cell flexCenter" style="font-weight: 700">
                耗材申购<br/>审批流程
              </el-col>
              <el-col :span="20" class="" style="">
                <el-row>
                  <el-col :span="4" class="cell flexCenter" style="">审批人</el-col>
                  <el-col :span="6" class="cell flexCenter">审批时间</el-col>
                  <el-col :span="6" class="cell flexCenter">审批人签名</el-col>
                  <el-col :span="8" class="cell flexCenter">审批意见</el-col>
                </el-row>

                <el-row v-for="(spitem, spidx) in processes" :key="spidx + 'item'">

                  <el-row v-for="(spuseritem, index) in spitem.sp_users" class="flexStretch"
                          v-if="(spitem.step_type=='or' && spitem.spover>0 && spuseritem.spover!=0) || !spitem.spover || spitem.spover==0 || spitem.step_type=='and'">
                  <el-col :span="4" class="cell flexCenter" style="">
                      {{ spuseritem.username ? spuseritem.username : spitem.teachers.typename }}
                    </el-col>
                    <el-col :span="6" class="cell flexCenter">{{ spuseritem.sptime }}</el-col>
                    <el-col :span="6" class="cell flexCenter" style="padding: 3px;">
                      <img v-if="spuseritem.handsign" :src="spuseritem.handsign" alt="" class="flexCenter"
                           style="max-height: 40px;max-width: 120px">
                    </el-col>
                    <el-col :span="8" class="cell flexStart breakWord" style="padding: 0 3px;">
                      {{ spuseritem.spover == 0 ? '【待审批】' : '' }}
                      {{ spuseritem.spover == 1 ? '【审批通过】' : '' }}
                      {{ spuseritem.spover == 2 ? '【审批驳回】' : '' }}
                      {{ spuseritem.spword }}
                    </el-col>
                  </el-row>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>

      </div>
    </div>


    <el-button v-if="false" v-print="'#printMe'" type="primary" style="position: fixed;top: 0;left: 0;z-index: 999;">
      打印
    </el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'export',
  components: {},
  data() {
    return {
      printObj: {
        id: 'printMe',
        popTitle: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      pdfok: false,
      pdfok1: false,
      DataInfo: {},
      config:{},
    }
  },
  props:['sgInfo'],
  mounted() {
    this.getConfig()
  },
  methods: {
    getConfig(pmid) {
      let _this = this
      let config = {}
      this.$http.post("/api/sys_dictionary_list",{pmid:pmid?pmid:''}).then(res => {
        if (res.data && res.data.length > 0) {
          res.data.map(a => {
            if (a.remark.indexOf('app基础配置') > -1 && a.key_values) {
              let json_Arr = JSON.parse(a.key_values)
              for (let item of json_Arr) {
                config[item.field] = item.value
              }
              this.config = config
              this.$nextTick(() => {
                _this.pdfok = true
                console.log("强制刷新")
                _this.$forceUpdate()
              })
            }
          })
        }
      })
    },
    upperCaseMoney(money) {
      // 汉字的数字
      var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
      //基本单位
      var cnIntRadice = new Array('', '拾', '佰', '仟');
      //对应整数部分扩展单位
      var cnIntUnits = new Array('', '万', '亿', '兆');
      //对应⼩数部分单位
      var cnDecUnits = new Array('⾓', '分', '毫', '厘');
      //整数⾦额时后⾯跟的字符
      var cnInteger = '整';
      //整型完以后的单位
      var cnIntLast = '元';
      //最⼤处理的数字
      var maxNum = 999999999999999.9999;
      //⾦额整数部分
      var integerNum;
      //⾦额⼩数部分
      var decimalNum;
      //输出的中⽂⾦额字符串
      var chineseStr = '';
      //分离⾦额后⽤的数组，预定义
      var parts;
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      if (money == '') {
        return '';
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最⼤处理数字
        return '';
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = '';
      } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }

      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == '0') {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //⼩数部分
      if (decimalNum != '') {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }


      }
      if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == '') {
        chineseStr += cnInteger;
      }
      return chineseStr;


    },

    //分割数组 arr：原数组  num：分割后每个数组里的数据条数
    util_spliceArrByN(arr, num) {
      var _arr = []
      while (arr.length) {
        _arr.push(arr.splice(0, num))
      }
      return _arr
    },
    //数组按某属性分组
    groupBy(array, f) {
      let groups = {}
      array.forEach(function (o) {
        let group = JSON.stringify(f(o))
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return Object.keys(groups).map(function (group) {
        return groups[group]
      })
    }
  }
}
</script>

<style lang="less" type="text/less" scoped>

#printMe {
  font-size: 14px;
  color: #303133;
  box-sizing: border-box;
  //border: 1px solid red;
  div {
    box-sizing: border-box;
  }
}

.form_label {
  font-size: 16px;
  white-space: nowrap;
}

.form_input {
  margin-left: 10px;
  flex: 1;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #303133;
}

.printtable {
  width: 100%;
  border-right: 1px solid #000;

  border-bottom: 1px solid #000;

  border-collapse: collapse;
}

.printtable td, .printtable th{
  border-left: 1px solid #000;

  border-top: 1px solid #000;
  //padding: 20px 10px;
  height: 30px;
  text-align: center;
}


.A4Container {
  width: 740px;
  //height: 1000px;
  margin: auto;
  page-break-after: always;
  //border: 1px solid red;
  padding: 20px 40px 20px;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 30px;
    font-size: 14px;
    color: #303133;
    word-break: break-word;
    white-space: normal;
    page-break-inside: avoid;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
  }
}

</style>
